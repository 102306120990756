<!--
 * @Author: 刘格优
 * @Date: 2020-03-21 10:59:16
 * @LastEditors: 顾俊杰
 * @LastEditTime: 2020-12-22 12:17:43
 -->
<template>
  <div>
    <md-field>
      <md-input-item title="上传证件号(正、反面)"
                     :solid="false"
                     disabled
                     class="tjmes"></md-input-item>
      <ul class="image-reader-list">
        <li class="image-reader-item add"
            v-if="cardfront === ''">
          <md-image-reader name="reader0"
                           @select="oncardfrontSelect"
                           @complete="oncardfrontComplete"
                           @error="oncardfrontError"
                           is-multiple></md-image-reader>
          <img src="@/assets/nh/zjz.png"
               alt="">
          <p>证件正面</p>
        </li>
        <li class="image-reader-item"
            v-if="cardfront !== ''">
          <div :style="{
          'backgroundImage': `url(${cardfront}?x-oss-process=image/resize,p_20)`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">

          </div>
          <p>证件正面</p>
          <md-tag class="image-reader-item-del"
                  size="small"
                  shape="quarter"
                  fill-color="#111A34"
                  type="fill"
                  font-color="#fff"
                  @click.native="onDeleteImage('cardfront')">
            <md-icon name="close"></md-icon>
          </md-tag>
        </li>
        <li class="image-reader-item add"
            v-if="cardback === ''">
          <md-image-reader name="reader0"
                           @select="oncardbackSelect"
                           @complete="oncardbackComplete"
                           @error="oncardbackError"
                           is-multiple></md-image-reader>
          <img src="@/assets/nh/zjf.png"
               alt="">
          <p>证件反面</p>
        </li>
        <li class="image-reader-item"
            v-if="cardback !== ''">
          <div :style="{
          'backgroundImage': `url(${cardback}?x-oss-process=image/resize,p_20)`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">

          </div>
          <p>证件反面</p>
          <md-tag class="image-reader-item-del"
                  size="small"
                  shape="quarter"
                  fill-color="#111A34"
                  type="fill"
                  font-color="#fff"
                  @click.native="onDeleteImage('cardback')">
            <md-icon name="close"></md-icon>
          </md-tag>
        </li>
      </ul>

    </md-field>
    <md-field>
      <md-input-item title="上传银行卡"
                     :solid="false"
                     disabled
                     class="tjmes"></md-input-item>
      <ul class="image-reader-list">
        <li class="image-reader-item add"
            v-if="bankcard === ''">
          <md-image-reader name="reader0"
                           @select="onbankcardSelect"
                           @complete="onbankcardComplete"
                           @error="onbankcardError"
                           is-multiple></md-image-reader>
          <img src="@/assets/nh/yhk.png"
               alt="">
          <p>银行卡</p>
        </li>
        <li class="image-reader-item"
            v-if="bankcard !== ''">
          <div :style="{
          'backgroundImage': `url(${bankcard}?x-oss-process=image/resize,p_20)`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">

          </div>
          <p>银行卡</p>
          <md-tag class="image-reader-item-del"
                  size="small"
                  shape="quarter"
                  fill-color="#111A34"
                  type="fill"
                  font-color="#fff"
                  @click.native="onDeleteImage('bankcard')">
            <md-icon name="close"></md-icon>
          </md-tag>
        </li>
      </ul>
      <md-field-item title="银行账户："
                       :content="bankname"
                       @click="showbankSelector"
                       arrow
                       :solid="false"
                       placeholder="请选择">
        </md-field-item>
      <md-selector v-model="bankSelectorShow"
                     :data="bankselectdata"
                     title="银行账户"
                     large-radius
                     max-height="350px"
                     @choose="onbankChoose"></md-selector>
        <md-input-item title="银行卡号："
                       placeholder="银行卡号"
                       :solid="false"
                       v-model="bankno"
                       align="right"></md-input-item>
        <md-input-item title="开户支行："
                       placeholder="如招商银行深圳市罗湖支行"
                       :solid="false"
                       v-model="bankzh"
                       align="right"></md-input-item>
    </md-field>
    <md-field>
      <md-input-item title="上传学历证书和一寸免冠照影像"
                     :solid="false"
                     disabled
                     class="tjmes"></md-input-item>
      <ul class="image-reader-list">
        <li class="image-reader-item add"
            v-if="edu === ''">
          <md-image-reader name="reader0"
                           @select="oneduSelect"
                           @complete="oneduComplete"
                           @error="oneduError"
                           is-multiple></md-image-reader>
          <img src="@/assets/nh/xlzs.png"
               alt="">
          <p>学历证书</p>
        </li>
        <li class="image-reader-item"
            v-if="edu !== ''">
          <div :style="{
          'backgroundImage': `url(${edu}?x-oss-process=image/resize,p_20)`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">

          </div>
          <p>学历证书</p>
          <md-tag class="image-reader-item-del"
                  size="small"
                  shape="quarter"
                  fill-color="#111A34"
                  type="fill"
                  font-color="#fff"
                  @click.native="onDeleteImage('edu')">
            <md-icon name="close"></md-icon>
          </md-tag>
        </li>
        <li class="image-reader-item add"
            v-if="headpic === ''">
          <md-image-reader name="reader0"
                           @select="onheadpicSelect"
                           @complete="onheadpicComplete"
                           @error="onheadpicError"
                           is-multiple></md-image-reader>
          <img src="@/assets/nh/mgz.png"
               alt="">
          <p>一寸免冠照</p>
        </li>
        <li class="image-reader-item"
            v-if="headpic !== ''">
          <div :style="{
          'backgroundImage': `url(${headpic}?x-oss-process=image/resize,p_20)`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
         }">
          </div>
          <p>一寸免冠照</p>
          <md-tag class="image-reader-item-del"
                  size="small"
                  shape="quarter"
                  fill-color="#111A34"
                  type="fill"
                  font-color="#fff"
                  @click.native="onDeleteImage('headpic')">
            <md-icon name="close"></md-icon>
          </md-tag>
        </li>
      </ul>
    </md-field>
    <md-field>
      <md-input-item title="手写签名"
                     class="tjmes require"
                     disabled
                     :solid="false"></md-input-item>
      <div style="background: #fff;padding: 10px;height: 6rem;position:relative">
        <div v-if="autograph === '' && selfautograph === ''"
             style="width:100%;height:100%;border:1px solid #f0f0f0;"
             id="contain"
             @touchstart="subtouchStart($event)">
          <VueSignaturePad ref="signaturePad" />
          <!-- <img src="./clear.jpg"
               alt=""
               style="position: absolute;right: 0;bottom:-10px;height:8.5vw;width:8.5vw;"
               @click="clearcanvas('qm')" /> -->
          <div style="position: absolute;right: 12px;top:-33px;color:#EC2D38"
               @touchend="clearcanvas('qm')">删除</div>
        </div>
        <div class="signature item_title"
             style="width: 100%;height:100%;border: 1px solid #f0f0f0;"
             v-else>
          <img :src="autograph"
               alt="签名"
               style="width:100%;height:100%"
               v-if="autograph">
          <div style="position: absolute;right: 12px;top:-33px;color:#EC2D38"
               @touchend="clearcanvas('tp')">删除</div>
          <!-- <img src="./clear.jpg"
               alt=""
               style="position: absolute;right: 0;bottom:-10px;height:8.5vw;width:8.5vw;"
               @click="clearcanvas('tp')" /> -->
        </div>
      </div>
    </md-field>
    <div class="btn">
      <div class="quitBtn"
           @click="submit">确定</div>
    </div>
  </div>
</template>

<script>
import { ididentify, uploadfiles, bankidentify, selectSourceInfo, saveSourceInfo, selectBaseBankcode } from '@/api/agent/agent'
import { Button, Field, Icon, ImageReader, Tag, Toast, FieldItem } from 'mand-mobile'
import { getStorage } from '@/lib/util'
import { cloneDeep } from 'lodash'
export default {
  components: {
    [Field.name]: Field,
    [Icon.name]: Icon,
    [ImageReader.name]: ImageReader,
    [Tag.name]: Tag,
    [FieldItem.name]: FieldItem,
    [Button.name]: Button
  },
  data () {
    return {
      // 图片相关
      cardfront: '',
      cardback: '',
      bankcard: '',
      edu: '',
      headpic: '',
      selfcardfront: '',
      selfcardback: '',
      selfbankcard: '',
      selfedu: '',
      selfheadpic: '',
      // 签名照
      autograph: '',
      selfautograph: '',
      getossurl: '',
      bankcode: '',
      bankname: '',
      bankno: '',
      bankzh: '',
      bankSelectorShow: false,
      bankselectdata: [
        {
          value: '0',
          text: '中国工商银行'
        }
      ],
      sourceinfo: []
    }
  },
  created () {
    this.getossurl = getStorage('ossurl', '')
    selectBaseBankcode().then((res) => {
      this.bankselectdata = res.data.data
      this.bankselectdata.forEach(item => {
        item.value = item.bankid
        item.text = item.bankname
      })
      selectSourceInfo().then((res) => {
        if (res.data.data.length !== 0) {
          res.data.data.forEach(item => {
            switch (item.soucode) {
              case 'idcardq':
                this.cardfront = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
                this.selfcardfront = item.souhttp
                break
              case 'idcardh':
                this.cardback = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
                //   this.getossurl + item.souhttp
                this.selfcardback = item.souhttp
                break
              case 'bank':
                this.bankcard = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
                this.selfbankcard = item.souhttp
                this.bankcode = item.bankcode
                this.bankselectdata.forEach(item => {
                  if (item.value == this.bankcode) {
                    this.bankname = item.text
                  }
                })
                this.bankzh = item.bankzh
                this.bankno = item.bankno
                break
              case 'xueli':
                this.edu = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
                this.selfedu = item.souhttp
                break
              case 'headone':
              // this.headpic = this.getossurl + item.souhttp
                this.headpic = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
                this.selfheadpic = item.souhttp
                break
              case 'autograph':
                this.autograph = (item.souhttp !== '') ? (this.getossurl + item.souhttp) : ''
                this.selfautograph = item.souhttp
                break
              default:
                break
            }
          })
          this.$forceUpdate()
        }
      })
    })
  },
  mounted () {
    if (this.$refs.signaturePad) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.signaturePad.$refs.signaturePadCanvas.width = document.getElementById('contain').offsetWidth
          this.$refs.signaturePad.$refs.signaturePadCanvas.height = document.getElementById('contain').offsetHeight
        }, 1500)
      })
    }
  },
  methods: {
    clearcanvas (type) {
      /*
      * 清空签名
      * */
      if (type == 'qm') {
        this.$refs.signaturePad.undoSignature()
      } else {
        this.autograph = ''
        this.selfautograph = ''
      }
    },
    subtouchStart (event) {
      event.preventDefault()
    },
    dataURLtoFile (dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      // 转换成file对象
      return new File([u8arr], filename, { type: mime })
    },

    async submit () {
      if (this.autograph === '' && this.selfautograph === '') {
        // console.log(this.$refs.signaturePad.saveSignature())
        const { data } = cloneDeep(this.$refs.signaturePad.saveSignature())
        if (data && data.length > 6) {
          let file = data // 把整个base64给file
          let name = '签名' + '.png' // 定义文件名字（例如：abc.png ， cover.png）
          let conversions = this.dataURLtoFile(file, name) // 调用base64转图片方法
          // conversions就是转化之后的图片文件，
          let formdata = new FormData()
          formdata.append('file', conversions)
          formdata.append('type', 'autograph')
          await uploadfiles(formdata).then(res => {
            this.selfautograph = res.data.data.path
            this.autograph = res.data.data.url
          })
        }
      }
      if (this.autograph === '') {
        Toast.failed('请手写签名')
        return
      }
      if (this.sourceinfo.length == 0) {
        this.sourceinfo.push({ souhttp: this.selfcardfront, soucode: 'idcardq' })
        this.sourceinfo.push({ souhttp: this.selfcardback, soucode: 'idcardh' })
        this.sourceinfo.push({ souhttp: this.selfbankcard, soucode: 'bank', bankcode: this.bankcode, bankzh: this.bankzh, bankno: this.bankno })
        this.sourceinfo.push({ souhttp: this.selfedu, soucode: 'xueli' })
        this.sourceinfo.push({ souhttp: this.selfheadpic, soucode: 'headone' })
        this.sourceinfo.push({ souhttp: this.selfautograph, soucode: 'autograph' })
      } else {
        this.sourceinfo.forEach((item, index) => {
          if (item.soucode == 'idcardq') {
            item.souhttp = this.selfcardfront
          } else if (item.soucode == 'idcardh') {
            item.souhttp = this.selfcardback
          } else if (item.soucode == 'bank') {
            item.souhttp = this.selfbankcard
            item.bankcode = this.bankcode
            item.bankzh = this.bankzh
            item.bankno = this.bankno
          } else if (item.soucode == 'xueli') {
            item.souhttp = this.selfedu
          } else if (item.soucode == 'headone') {
            item.souhttp = this.selfheadpic
          } else if (item.soucode == 'autograph') {
            item.souhttp = this.selfautograph
          }
        })
      }
      saveSourceInfo({ sourceinfo: this.sourceinfo }).then((res) => {
        Toast.info('提交成功')
      })
    },
    // 删除图片
    onDeleteImage (tag) {
      switch (tag) {
        case 'bankcard':
          this.bankcard = ''
          this.bankname = ''
          this.bankno = ''
          this.selfbankcard = ''
          break
        case 'cardfront':
          this.cardfront = ''
          this.selfcardfront = ''
          break
        case 'cardback':
          this.cardback = ''
          this.selfcardback = ''
          break
        case 'edu':
          this.edu = ''
          this.selfedu = ''
          break
        case 'headpic':
          this.headpic = ''
          this.selfheadpic = ''
          break
        default:
          break
      }
    },
    // 图片选择
    oncardfrontSelect () {
      Toast.loading('图片读取中...')
      setTimeout(() => {
        Toast.hide()
      }, 2000)
    },
    oncardfrontComplete (name, { dataUrl, file }) {
      Toast.hide()
      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', '0')
      ididentify(formdata).then(res => {

      })
      formdata.delete('type')
      formdata.append('type', 'idcard')
      uploadfiles(formdata).then(res => {
        this.selfcardfront = res.data.data.path
        this.cardfront = res.data.data.url
      })
    },
    oncardfrontError () { },
    oncardbackSelect () {
      Toast.loading('图片读取中...')
      setTimeout(() => {
        Toast.hide()
      }, 2000)
    },
    oncardbackComplete (name, { dataUrl, file }) {
      Toast.hide()
      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', '1')
      ididentify(formdata).then(res => {
      })
      formdata.delete('type')
      formdata.append('type', 'idcard')
      uploadfiles(formdata).then(res => {
        this.selfcardback = res.data.data.path
        this.cardback = res.data.data.url
      })
    },
    oncardbackError () { },
    onbankcardSelect () {
      Toast.loading('图片读取中...')
      setTimeout(() => {
        Toast.hide()
      }, 2000)
    },
    onbankcardComplete (name, { dataUrl, file }) {
      Toast.hide()
      let formdata = new FormData()
      formdata.append('file', file)
      bankidentify(formdata).then(res => {
        this.bankname = res.data.data.bank_name ? res.data.data.bank_name : ''
        this.bankno = res.data.data.bank_card_number ? res.data.data.bank_card_number : ''
      })
      formdata.delete('type')
      formdata.append('type', 'bank')
      uploadfiles(formdata).then(res => {
        this.selfbankcard = res.data.data.path
        this.bankcard = res.data.data.url
      })
    },
    onbankcardError () { },
    oneduSelect () {
      Toast.loading('图片读取中...')
      setTimeout(() => {
        Toast.hide()
      }, 2000)
    },
    oneduComplete (name, { dataUrl, file }) {
      Toast.hide()

      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', 'xueli')
      uploadfiles(formdata).then(res => {
        this.selfedu = res.data.data.path
        this.edu = res.data.data.url
      })
    },
    oneduError (name, { msg }) { Toast.failed(msg) },
    onheadpicSelect () {
      Toast.loading('图片读取中...')
      setTimeout(() => {
        Toast.hide()
      }, 2000)
    },
    onheadpicComplete (name, { dataUrl, file }) {
      Toast.hide()
      let formdata = new FormData()
      formdata.append('file', file)
      formdata.append('type', 'headone')
      uploadfiles(formdata).then(res => {
        this.selfheadpic = res.data.data.path
        this.headpic = res.data.data.url
      })
    },
    showbankSelector () { this.bankSelectorShow = true },
    onbankChoose ({ value, text }) {
      this.bankcode = value
      this.bankname = text
    },
    onheadpicError () { }
  }
}

</script>
<style scoped lang="stylus">
/deep/ .md-field {
  padding: 0 0.4rem;
  border: none;
  background-color: #fff;
  margin-bottom: 0.3rem;
}

/deep/ .md-field-item-content {
  min-height: 1.3rem;
}

/deep/ .md-field-item-title {
  padding-left: 2.7vw;
}

/deep/ .require {
  .md-field-item-title::after {
    content: '*';
    color: red;
  }
}

/deep/ .md-field-item-control {
  color: #111a34 !important;
}

/deep/ .tjmes {
  .md-field-item-title {
    padding-left: 0;
    font-weight: bold;
    font-size: 0.35rem;
  }
}

.image-reader-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.image-reader-item {
  position: relative;
  float: left;
  width: 48%;
  margin-bottom: 2%;
  background: #FFF;
  // box-shadow: 0 5px 20px rgba(197, 202, 213, 0.25);
  box-sizing: border-box;
  list-style: none;
  border-radius: 4px;
  background-size: cover;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }

  div {
    height: 2.5rem;
  }

  &:nth-of-type(4n) {
    margin-right: 0;
  }

  p {
    width: 100%;
    margin-top: 15px;
    font-size: 32px;
    color: #CBC;
    text-align: center;
  }

  &.add {
    .md-icon {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.8;
    }

    p {
      width: 100%;
      margin-top: 15px;
      font-size: 32px;
      color: #CBC;
      text-align: center;
    }
  }

  .image-reader-item-del {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    opacity: 0.8;

    .md-icon-close {
      font-size: 24px;
    }
  }
}

/deep/ .md-field-item-control {
  display: flex;
  justify-content: flex-end;
}

.store {
  border: none;
  box-shadow: none;
}

.marginb {
  margin-bottom: 0.3rem;
}

.btn {
  text-align: center;
  padding-top: 100px;

  .quitBtn {
    padding: 30px 30px;
    margin: 80px auto;
    width: 90%;
    background-color: color-primary;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    border-radius: 10px;
  }
}
</style>
