var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('md-field',[_c('md-input-item',{staticClass:"tjmes",attrs:{"title":"上传证件号(正、反面)","solid":false,"disabled":""}}),_c('ul',{staticClass:"image-reader-list"},[(_vm.cardfront === '')?_c('li',{staticClass:"image-reader-item add"},[_c('md-image-reader',{attrs:{"name":"reader0","is-multiple":""},on:{"select":_vm.oncardfrontSelect,"complete":_vm.oncardfrontComplete,"error":_vm.oncardfrontError}}),_c('img',{attrs:{"src":require("@/assets/nh/zjz.png"),"alt":""}}),_c('p',[_vm._v("证件正面")])],1):_vm._e(),(_vm.cardfront !== '')?_c('li',{staticClass:"image-reader-item"},[_c('div',{style:({
        'backgroundImage': `url(${_vm.cardfront}?x-oss-process=image/resize,p_20)`,
        'backgroundPosition': 'center center',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': 'cover'
       })}),_c('p',[_vm._v("证件正面")]),_c('md-tag',{staticClass:"image-reader-item-del",attrs:{"size":"small","shape":"quarter","fill-color":"#111A34","type":"fill","font-color":"#fff"},nativeOn:{"click":function($event){return _vm.onDeleteImage('cardfront')}}},[_c('md-icon',{attrs:{"name":"close"}})],1)],1):_vm._e(),(_vm.cardback === '')?_c('li',{staticClass:"image-reader-item add"},[_c('md-image-reader',{attrs:{"name":"reader0","is-multiple":""},on:{"select":_vm.oncardbackSelect,"complete":_vm.oncardbackComplete,"error":_vm.oncardbackError}}),_c('img',{attrs:{"src":require("@/assets/nh/zjf.png"),"alt":""}}),_c('p',[_vm._v("证件反面")])],1):_vm._e(),(_vm.cardback !== '')?_c('li',{staticClass:"image-reader-item"},[_c('div',{style:({
        'backgroundImage': `url(${_vm.cardback}?x-oss-process=image/resize,p_20)`,
        'backgroundPosition': 'center center',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': 'cover'
       })}),_c('p',[_vm._v("证件反面")]),_c('md-tag',{staticClass:"image-reader-item-del",attrs:{"size":"small","shape":"quarter","fill-color":"#111A34","type":"fill","font-color":"#fff"},nativeOn:{"click":function($event){return _vm.onDeleteImage('cardback')}}},[_c('md-icon',{attrs:{"name":"close"}})],1)],1):_vm._e()])],1),_c('md-field',[_c('md-input-item',{staticClass:"tjmes",attrs:{"title":"上传银行卡","solid":false,"disabled":""}}),_c('ul',{staticClass:"image-reader-list"},[(_vm.bankcard === '')?_c('li',{staticClass:"image-reader-item add"},[_c('md-image-reader',{attrs:{"name":"reader0","is-multiple":""},on:{"select":_vm.onbankcardSelect,"complete":_vm.onbankcardComplete,"error":_vm.onbankcardError}}),_c('img',{attrs:{"src":require("@/assets/nh/yhk.png"),"alt":""}}),_c('p',[_vm._v("银行卡")])],1):_vm._e(),(_vm.bankcard !== '')?_c('li',{staticClass:"image-reader-item"},[_c('div',{style:({
        'backgroundImage': `url(${_vm.bankcard}?x-oss-process=image/resize,p_20)`,
        'backgroundPosition': 'center center',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': 'cover'
       })}),_c('p',[_vm._v("银行卡")]),_c('md-tag',{staticClass:"image-reader-item-del",attrs:{"size":"small","shape":"quarter","fill-color":"#111A34","type":"fill","font-color":"#fff"},nativeOn:{"click":function($event){return _vm.onDeleteImage('bankcard')}}},[_c('md-icon',{attrs:{"name":"close"}})],1)],1):_vm._e()]),_c('md-field-item',{attrs:{"title":"银行账户：","content":_vm.bankname,"arrow":"","solid":false,"placeholder":"请选择"},on:{"click":_vm.showbankSelector}}),_c('md-selector',{attrs:{"data":_vm.bankselectdata,"title":"银行账户","large-radius":"","max-height":"350px"},on:{"choose":_vm.onbankChoose},model:{value:(_vm.bankSelectorShow),callback:function ($$v) {_vm.bankSelectorShow=$$v},expression:"bankSelectorShow"}}),_c('md-input-item',{attrs:{"title":"银行卡号：","placeholder":"银行卡号","solid":false,"align":"right"},model:{value:(_vm.bankno),callback:function ($$v) {_vm.bankno=$$v},expression:"bankno"}}),_c('md-input-item',{attrs:{"title":"开户支行：","placeholder":"如招商银行深圳市罗湖支行","solid":false,"align":"right"},model:{value:(_vm.bankzh),callback:function ($$v) {_vm.bankzh=$$v},expression:"bankzh"}})],1),_c('md-field',[_c('md-input-item',{staticClass:"tjmes",attrs:{"title":"上传学历证书和一寸免冠照影像","solid":false,"disabled":""}}),_c('ul',{staticClass:"image-reader-list"},[(_vm.edu === '')?_c('li',{staticClass:"image-reader-item add"},[_c('md-image-reader',{attrs:{"name":"reader0","is-multiple":""},on:{"select":_vm.oneduSelect,"complete":_vm.oneduComplete,"error":_vm.oneduError}}),_c('img',{attrs:{"src":require("@/assets/nh/xlzs.png"),"alt":""}}),_c('p',[_vm._v("学历证书")])],1):_vm._e(),(_vm.edu !== '')?_c('li',{staticClass:"image-reader-item"},[_c('div',{style:({
        'backgroundImage': `url(${_vm.edu}?x-oss-process=image/resize,p_20)`,
        'backgroundPosition': 'center center',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': 'cover'
       })}),_c('p',[_vm._v("学历证书")]),_c('md-tag',{staticClass:"image-reader-item-del",attrs:{"size":"small","shape":"quarter","fill-color":"#111A34","type":"fill","font-color":"#fff"},nativeOn:{"click":function($event){return _vm.onDeleteImage('edu')}}},[_c('md-icon',{attrs:{"name":"close"}})],1)],1):_vm._e(),(_vm.headpic === '')?_c('li',{staticClass:"image-reader-item add"},[_c('md-image-reader',{attrs:{"name":"reader0","is-multiple":""},on:{"select":_vm.onheadpicSelect,"complete":_vm.onheadpicComplete,"error":_vm.onheadpicError}}),_c('img',{attrs:{"src":require("@/assets/nh/mgz.png"),"alt":""}}),_c('p',[_vm._v("一寸免冠照")])],1):_vm._e(),(_vm.headpic !== '')?_c('li',{staticClass:"image-reader-item"},[_c('div',{style:({
        'backgroundImage': `url(${_vm.headpic}?x-oss-process=image/resize,p_20)`,
        'backgroundPosition': 'center center',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': 'cover'
       })}),_c('p',[_vm._v("一寸免冠照")]),_c('md-tag',{staticClass:"image-reader-item-del",attrs:{"size":"small","shape":"quarter","fill-color":"#111A34","type":"fill","font-color":"#fff"},nativeOn:{"click":function($event){return _vm.onDeleteImage('headpic')}}},[_c('md-icon',{attrs:{"name":"close"}})],1)],1):_vm._e()])],1),_c('md-field',[_c('md-input-item',{staticClass:"tjmes require",attrs:{"title":"手写签名","disabled":"","solid":false}}),_c('div',{staticStyle:{"background":"#fff","padding":"10px","height":"6rem","position":"relative"}},[(_vm.autograph === '' && _vm.selfautograph === '')?_c('div',{staticStyle:{"width":"100%","height":"100%","border":"1px solid #f0f0f0"},attrs:{"id":"contain"},on:{"touchstart":function($event){return _vm.subtouchStart($event)}}},[_c('VueSignaturePad',{ref:"signaturePad"}),_c('div',{staticStyle:{"position":"absolute","right":"12px","top":"-33px","color":"#EC2D38"},on:{"touchend":function($event){return _vm.clearcanvas('qm')}}},[_vm._v("删除")])],1):_c('div',{staticClass:"signature item_title",staticStyle:{"width":"100%","height":"100%","border":"1px solid #f0f0f0"}},[(_vm.autograph)?_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.autograph,"alt":"签名"}}):_vm._e(),_c('div',{staticStyle:{"position":"absolute","right":"12px","top":"-33px","color":"#EC2D38"},on:{"touchend":function($event){return _vm.clearcanvas('tp')}}},[_vm._v("删除")])])])],1),_c('div',{staticClass:"btn"},[_c('div',{staticClass:"quitBtn",on:{"click":_vm.submit}},[_vm._v("确定")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }